import * as React from "react";
import { withUnpublishedPreview } from "gatsby-source-prismic";

import CaseTemplate from "../page-templates/case";

const NotFoundPage = () => <h1>Page not found!</h1>;

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    case: CaseTemplate,
  },
});
